<template>
  <div class="mb-4">
    <div class="col-sm-10 job-allocation border-light">
      <h5 class="text-primary text-center mt-3 mx-3">Job Allocation</h5>
      <div>
        <ValidationObserver ref="allocateJob" v-slot="{ handleSubmit }">
          <form id="create" @submit.prevent="handleSubmit()">
            <CRow class="mt-3 ml-3">
              <CCol md="4">
                <CRow class="row pb-3">
                  <label class="col-lg-12 col-md-12 required">Select Job</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="job_id"
                        :value="allocation.job_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['job'] ? options['job'] : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="4">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Select Organisation</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="allocated_organisation_id"
                        :value="allocation.allocated_organisation_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['organisation']
                            ? options['organisation']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="3">
                <label class="col-lg-12 col-md-12 pb-2 margin-2"></label>
                <CButton
                  class="btn-primary py-1"
                  v-if="
                    edit_allocation_id ||
                    allocation.job_id ||
                    allocation.allocated_organisation_id
                  "
                  @click="confirmJobAllocation"
                >
                  {{ edit_allocation_id ? "Update" : "Allocate" }}
                </CButton>
                <button
                  name="cancel"
                  v-if="
                    edit_allocation_id ||
                    allocation.job_id ||
                    allocation.allocated_organisation_id
                  "
                  class="hand-icon action-button mx-4 margin-2"
                  v-c-tooltip="'Cancel'"
                  @click="resetFields()"
                >
                  <CIcon name="cil-x-circle" style="margin-right: 20px" />
                </button>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
      <hr class="mb-4" />
      <CDataTable
        :striped="true"
        :responsive="true"
        :small="true"
        :items="getItems"
        :fields="fields"
        :items-per-page="10"
        :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
        sorter
        pagination
      >
        <template #action="{ item }">
          <td class="py-2">
            <CButton
              color="primary"
              square
              size="sm"
              class="m-1"
              @click="editAllocation(item)"
              v-c-tooltip="'Edit'"
              ><em class="far fa-edit"></em
            ></CButton>
            <CButton
              color="primary"
              square
              size="sm"
              class="m-1"
              @click="deleteJobAllocation(item)"
              v-c-tooltip="'Remove'"
              ><em class="far fa-trash-alt"></em
            ></CButton>
            <!-- <CButton
                class="hand-icon"
                @click="editAllocation(item)"
                :style="'cursor:pointer'"
              >
                <i class="fas fa-pencil-alt" v-c-tooltip="'Edit'"></i>
              </CButton>
              <CButton
                class="hand-icon"
                @click="deleteJobAllocation(item)"
                :style="'cursor:pointer'"
              >
                <i class="fas fa-trash-alt" v-c-tooltip="'Remove'"></i>
              </CButton> -->
          </td>
        </template>
        <template #job_display_uid={item}>
          <td>
            <router-link :to="`job-list-preview/${item.job_id}`" class="text-primary" event="" @click.native.prevent="navToJob(item.job_id)">{{item.job_display_uid}}</router-link>
          </td>
        </template>
      </CDataTable>
      <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
        <CToast :class="toastClass" :show="true">{{ toastMessage }}</CToast>
      </CToaster>
      <Modal
        v-if="confirmModal.isShowPopup"
        :modalTitle="confirmModal.modalTitle"
        :modalColor="confirmModal.modalColor"
        :modalContent="confirmModal.modalContent"
        :isShowPopup="confirmModal.isShowPopup"
        :buttons="confirmModal.buttons"
        :modalCallBack="confirmModalCallBack"
        :closeOnBackdrop="false"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Select from "../components/reusable/Fields/Select.vue";
import Modal from "@/components/reusable/Modal";

export default {
  components: { Select, Modal },
  name: "JobAllocation",
  data: () => ({
    payload: {},
    allocation: {},
    hasErrror: "",
    edit_allocation_id: null,
    delete_allocation_id: null,
    filtered_jobs: null,
    isEdit: false,
    fields: [
      // { key: "job_id", label: "Job ID", _style: "width:10%" },
      { key: "job_display_uid", label: "Job ID", _style: "width:10%" },
      { key: "job_title", label: "Job Title", _style: "width:25%" },
      {
        key: "allocated_organisation",
        label: "Allocated Agency",
        _style: "width:20%",
      },
      { key: "action", label: "Action", _style: "width:15%" },
    ],
    confirmModal: {
      modalColor: "danger",
      modalTitle: "NOTIFICATION",
      modalContent: "",
      isShowPopup: false,
      buttons: ["Cancel", "Confirm"],
    },
    allocationExist: false,
  }),
  computed: {
    ...mapGetters([
      "toast",
      "toastMessage",
      "toastClass",
      "toastHide",
      "getCustomerId",
      "organizationsForJobAllocation",
      "getAllocatedJobs",
      "getJobsForAllocation",
      "getAgencyOrganisations",
    ]),
    options() {
      return {
        job: this.getJobsForAllocation,
        organisation: this.getAgencyOrganisations,
      };
    },
    getFilteredJobs() {
      this.filtered_jobs = this.getJobsForAllocation;
      return this.getAllocatedJobs &&
        this.filtered_jobs &&
        this.filtered_jobs.length
        ? this.filtered_jobs.filter(
            (val) =>
              !this.getAllocatedJobs?.some(({ job_id }) => job_id === val?.code)
          )
        : this.filtered_jobs;
    },
    getItems() {
      return this.getAllocatedJobs?.length
        ? this.getAllocatedJobs.map((val) => ({
            job_id: val?.job_id || "--",
            job_display_uid: val?.job ? val?.job.display_uid : "--" || "--",
            job_title: val?.job?.job_title || "--",
            allocated_organisation: val?.allocated_organisation?.name || "--",
            job_allocation_id: val?.job_allocation_id || null,
            allocated_organisation_id: val?.allocated_organisation_id || null,
          }))
        : [];
    },
  },
  methods: {
    ...mapActions([
      "scrollTop",
      "showToast",
      "allocateJob",
      "reallocateJob",
      "deleteAllocation",
      "fetchAllocatedJobs",
      "initJobAllocationFetchOptions",
    ]),
    navToJob(job_id){
      let fullPath = this.$route.fullPath;
      this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      this.$router.push({ path: `/job-list-preview/${job_id}` });
    },
    handleChangeSelect(name, value) {
      Vue.set(this.allocation, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    editAllocation(data) {
      this.scrollTop();
      this.isEdit = true;
      this.allocation = {
        ...this.allocation,
        job_id: {
          code: data?.job_id != "--" ? data.job_id : null,
          label:
            data?.job_id != "--" && data?.job_title != "--"
              ? `${data?.job_id} | ${data.job_title}`
              : null,
        },
        allocated_organisation_id: {
          label:
            data.allocated_organisation !== "--"
              ? data.allocated_organisation
              : null,
          code:
            data.allocated_organisation_id !== "--"
              ? data.allocated_organisation_id
              : null,
        },
      };
      this.edit_allocation_id = data?.job_allocation_id;
    },
    deleteJobAllocation(data) {
      this.confirmModal.modalContent = `Confirm removal of job - ${data.job_id} - ${data.allocated_organisation} ?`;
      this.delete_allocation_id = data.job_allocation_id;
      this.confirmModal.isShowPopup = true;
    },
    checkAllocationExist() {
      const allocated_org_id =
        this.allocation?.allocated_organisation_id?.code || null;
      const allocated_job_id = this.allocation?.job_id?.code || null;
      if (
        !this.isEdit &&
        this.getAllocatedJobs?.length &&
        allocated_org_id &&
        allocated_job_id &&
        this.getAllocatedJobs?.some(
          ({ job_id, allocated_organisation_id }) =>
            allocated_job_id === job_id &&
            allocated_org_id === allocated_organisation_id
        )
      ) {
        this.allocationExist = true;
        this.showToast({
          class: "bg-danger text-white",
          message: "Allocation Already Exist!",
        });
        return;
      } else if (
        this.isEdit &&
        this.getAllocatedJobs?.length &&
        allocated_org_id &&
        allocated_job_id &&
        this.getAllocatedJobs
          ?.filter(
            ({ job_allocation_id }) =>
              job_allocation_id != this.edit_allocation_id
          )
          .some(
            ({ job_id, allocated_organisation_id }) =>
              allocated_job_id === job_id &&
              allocated_org_id === allocated_organisation_id
          )
      ) {
        this.allocationExist = true;
        this.showToast({
          class: "bg-danger text-white",
          message: "Allocation Already Exist!",
        });
      } else {
        this.allocationExist = false;
        this.allocateTheJob();
      }
    },
    async confirmJobAllocation() {
      const isValid = await this.$refs.allocateJob.validate();
      this.errors = Object.values(this.$refs.allocateJob.errors).flat();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (isValid && this.isEdit) {
        this.checkAllocationExist();
      } else if (isValid) {
        this.confirmModal.modalContent = `Please confirm you wish to allocte Job ID ${
          this.allocation?.job_id?.label ? this.allocation.job_id?.label : "--"
        } to: ${
          this.allocation?.allocated_organisation_id?.label
            ? this.allocation.allocated_organisation_id.label
            : "--"
        } ?`;
        this.delete_allocation_id = null;
        this.delete_allocation_id = null;
        this.confirmModal.isShowPopup = true;
      }
    },
    confirmModalCallBack(action) {
      if (this.delete_allocation_id && action === "Confirm") {
        this.deleteAllocation(this.delete_allocation_id);
      } else if (action === "Confirm") {
        this.checkAllocationExist();
      }
      if (!this.allocationExist) this.resetFields();
      this.delete_allocation_id = null;
      this.confirmModal.isShowPopup = false;
    },
    resetFields() {
      this.allocation = {};
      this.edit_allocation_id = null;
      this.isEdit = false;
      this.$nextTick(() => {
        this.$refs.allocateJob.reset();
        this.$refs.allocateJob.errors.clear;
      });
    },
    async allocateTheJob() {
      let appendAction;
      let finalPayload = {
        job_id: this.allocation.job_id?.code,
        allocated_organisation_id:
          this.allocation.allocated_organisation_id?.code,
      };
      if (this.isEdit && this.edit_allocation_id) {
        finalPayload = {
          ...finalPayload,
          job_allocation_id: this.edit_allocation_id,
        };
        appendAction = this.reallocateJob(finalPayload).then((res) => {
          this.isEdit = false;
        });
      } else {
        appendAction = this.allocateJob(finalPayload);
      }
      Promise.all([appendAction]).then((res) => {
        this.edit_allocation_id = null;
        this.resetFields();
      });
    },
  },
  mounted() {
    this.scrollTop();
    this.initJobAllocationFetchOptions();
  },
};
</script>

<style lang="scss" scoped>
.job-allocation {
  background-color: white;
  margin: auto;
  min-height: 325px;
}

@media all and (max-width: 480px) {
  .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .v-select {
    width: 250px;
  }
}
@media all and (max-width: 768px) {
  .hidden-label {
    display: none;
  }
}
.margin-2 {
  margin-bottom: 0.8rem;
}
.action-button {
  width: 20px;
  height: 20px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.hand-icon {
  color: #3c4b64;
  cursor: pointer;
}
.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
  width: 2rem;
  height: 1.7rem;
  color: #3c4b64;
}
</style>
